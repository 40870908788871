import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#EEEFEF',
      neutralDark1: '#000000',
      neutralDark2: '#77AAB2',
      neutralDark3: '#3c454b',
      primaryDark: '#3A454C',
      secondaryDark: '#C0002E',
      primaryLight: '#DEEBF4',
      secondaryLight: '#F8EDED',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'Fraunces', serif",
    paragraph: "'Noto Sans', sans-serif",
    cursive: "'Homemade Apple', cursive",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    margin: 2.5vw 0;
    font-size: 6.5vw;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.neutralLight1};

    @media (max-width: 991px) {
      font-size: 70px;
    }
  `,
  h2: css`
    font-size: clamp(32px, 5vw, 5vw);
    font-weight: 400;
    line-height: 1;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    strong {
      color: ${theme.colors.variants.secondaryDark};
    }

    em {
      font-size: clamp(24px, 3vw, 3vw);
    }

    @media (max-width: 991px) {
      font-size: 50px;
      line-height: 55px;
    }
  `,
  h3: css`
    line-height: 1;
    font-size: clamp(55px, 3.5vw, 70px);
    font-weight: 400;
    margin-bottom: 15px;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  `,
  h4: css`
    line-height: 1;
    font-size: clamp(45px, 2.1vw, 2.1vw);
    font-weight: 400;
    margin-bottom: 15px;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1399px) {
      font-size: 3vw;
    }

    @media (max-width: 991px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  `,

  label: css`
    letter-spacing: 1px;
    font-size: clamp(19px, 1.1vw, 1.1vw);
    font-weight: 300;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  `,

  cursive: css`
    font-size: clamp(24px, 2vw, 2vw);
    line-height: 1.85;
    font-family: ${theme.fontFamily.cursive};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  `,
  description: css`
    font-size: clamp(14px, 1vw, 18px);
    font-weight: 200;
    line-height: 1.8;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 22px;
    }
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        <ParallaxProvider>{children}</ParallaxProvider>
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
